import * as types from "./mutation-types";

const state = () => {
  return {
    darkMode: false,
    User: false,
    userData: null,
    local: null, 
  };
};

// getters
const getters = {
  darkMode: state => state.darkMode,
  local: state => state.local,
};

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode });
  },
  setUserLogged({ commit }, User) {
    commit(types.SET_USER_LOGGED, { User });
  },
  setLocal({ commit }, local) {  
    commit(types.SET_LOCAL, { local });
  },
  setUserData({ commit }, data) {
    commit(types.SET_USER_DATA, { data });
  },
};

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode;
  },
  [types.SET_USER_LOGGED](state, { User }) {
    state.User = User;
  },
  [types.SET_LOCAL](state, { local }) {
    state.local = local;
  },
  [types.SET_USER_DATA](state, { data }) {
    state.userData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
