'use strict';

const $_firebase = require("./firebase");

const dataset    = require("./dataset");
const event      = require("./event");
const { Response } = require("node-fetch");
//const Response   = require("node-fetch");
const axios      = require('axios').default;
const lzstring   = require('lz-string');
const project    = require("./project");
/*
//import config from '@/etc/rosepetal.json';
const config    = {
    "awsConfig": {
		"accessKeyId": 		"AKIA4CSSZ4QNDT6ERIFZ",
		"secretAccessKey":  "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",
		"region":			"us-east-1",
		"bucket": 			"lookoutvision-projects-us-east-1",
        "bucket2": 			"lookoutvision-us-east-1-8af8e52d55",
		"projectId": 		$_firebase.getConfig().projectId 
	}
}*/


/*config.awsConfig = await project.getConfig("aws")

var S3 = require('aws-sdk/clients/s3');

var s3 = new S3({ 
    region:       config.awsConfig.region, 
    credentials:  { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey },
    s3Uri:        "s3://"+ config.awsConfig.bucket + "/projects/" + config.awsConfig.projectId + "/upload" + "/manual/"
});

var LookoutVisionClient = require('aws-sdk/clients/lookoutvision');
const lookoutConfig     = { region: "us-east-1", credentials: { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey } }
var lookoutvision       = new LookoutVisionClient(lookoutConfig);

var GreengrassClient = require('aws-sdk/clients/greengrass');
const greengrassConfig  = { region: "us-east-1", credentials: { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey } }
var greengrass       = new GreengrassClient(greengrassConfig);
*/

const aws = { 
    initAws() {
        console.log('in rosepetal model initAws()')
        return new Promise(async (resolve, reject) => {
            project.getSettings("aws").then((awsConfig) => {
                if(awsConfig){
                    const config     = { awsConfig: awsConfig}
                    this.config      = config
                    var S3 = require('aws-sdk/clients/s3');
                    const s3Config = { 
                        region:       config.awsConfig.region, 
                        credentials:  { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey },
                        s3Uri:        "s3://"+ config.awsConfig.bucket + "/projects/" + config.awsConfig.projectId + "/upload" + "/manual/"
                    }
                    this.s3 = new S3(s3Config);
                    var LookoutVisionClient = require('aws-sdk/clients/lookoutvision');
                    const lookoutConfig = { region: "us-east-1", credentials: { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey } }
                    this.lookoutConfig  = lookoutConfig;
                    this.lookoutvision  = new LookoutVisionClient(lookoutConfig);
                    
                    var GreengrassClient = require('aws-sdk/clients/greengrass');
                    const greengrassConfig  = { region: "us-east-1", credentials: { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey } }
                    this.greengrass       = new GreengrassClient(greengrassConfig);
                    resolve("aws init success")
                }else{
                    reject("aws init failed")
                }
            })
        }) 
    },

    getConfig: function() {
        return this.config.awsConfig
    },

    getBucketCors: async function() {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: this.config.awsConfig.bucket }
            this.s3.getBucketCors( { Bucket: resp.bucket },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data.CORSRules
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    getBucketAcl: async function() {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: this.config.awsConfig.bucket }
            this.s3.getBucketAcl( { Bucket: resp.bucket },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    getBucketObjects: async function() {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: this.config.awsConfig.bucket }
            this.s3.listObjects( { Bucket: resp.bucket },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    getObject: async function(key , renderHtml = false) {
        console.log('getObject in bucket: ' + this.config.awsConfig.bucket + ' key: ' + key)
        let p = new Promise((resolve, reject) => {
            let resp  = { status: "error", error: false, bucket: this.config.awsConfig.bucket, key: key } //, render: "TEST"
            if(key){
                this.s3.getObject( { Bucket: resp.bucket, Key: key },async function(err, data) {
                    if (!err){
                        resp.status   = "success"
                        resp.response = data
                        resp.extension = key.toString().split('.').pop()
                        resolve(resp)
                    }
                    if(err){ //si no existe en el bucket principal del proyecto, miramos si lo ha creado en el secundario
                        console.log('miramos en el otro bucket' + this.config.awsConfig.bucket2)
                        resp.error = err; 
                        this.s3.getObject( { Bucket: this.config.awsConfig.bucket2, Key: key },async function(err, data) {
                            if (!err){
                                resp.status   = "success"
                                resp.response = data
                                resp.extension = key.toString().split('.').pop()
                                resolve(resp)
                            }
                            resp.error = err; reject
                            resolve(resp)
                        })
                    }
                    resp.error = err; 
                    //reject
                    //resolve(resp)
                })
            }else{
                resp.error = "key object is required"; reject
                resolve(resp)
            }
        }) 
        let obj = await p 
        if(renderHtml && obj.response && obj.response.Body){
            let acceptedRender = ['jpg', 'gif', 'png', 'bmp'];
            if (obj.extension && acceptedRender.includes(obj.extension.toString().toLowerCase())) {
                let res        = new Response(obj.response.Body) 
                let buffer     = await res.arrayBuffer()
                obj.renderSrc  = "data:image/png;base64," + Buffer.from(buffer, 'binary').toString('base64')
                if(renderHtml=='64') obj.render = obj.renderSrc
                else obj.render = '<img width="250" height="250" src="' + obj.renderSrc + '" />'
            }
            delete obj.response.Body
        }
        return obj   
    },

    getObjectAttributes: async function(key) {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: this.config.awsConfig.bucket, key: key }
            if(key){
                this.s3.getObjectAttributes( { Bucket: resp.bucket, Key: key, ObjectAttributes: ["ETag", "ObjectSize", "Checksum", "StorageClass", "ObjectParts"] },function(err, data) {
                    if (!err){
                        resp.status   = "success"
                        resp.response = data
                        resolve(resp)
                    }
                    resp.error = err; reject
                    resolve(resp)
                })
            }else{
                resp.error = "key object is required"; reject
                resolve(resp)
            }
        }) 
    },

    deleteObject: async function(objKey) {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: this.config.awsConfig.bucket }
            if (objKey){
                resp.objKey   = objKey
                let delParams = {
                    Bucket: resp.bucket,
                    Key:    objKey,
                };
                this.s3.deleteObject( delParams ,function(err, data) {
                    if (!err){
                        resp.status   = "success"
                        resp.response = data
                        resolve(resp)
                    }
                    resp.error = err; reject
                    resolve(resp)
                })
            }else{ resp.error = "object Key is required"; resolve(resp) }
        }) 
    },
    
    dataURLtoBlob: function(dataURL) {
        let byteString = atob(dataURL.split(',')[1]);
        let mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) { uint8Array[i] = byteString.charCodeAt(i);}
        return new Blob([arrayBuffer], { type: mimeString });
    },

    /*removeMaskColors: async function(mask) {
        return new Promise((resolve, reject) => {
            var canvasElement = document.createElement('canvas');
            canvasElement.setAttribute('id', 'canvas');
            canvasElement.setAttribute('width', mask.width);
            canvasElement.setAttribute('height', mask.height);
            let saveCanvas  = new fabric.Canvas(canvasElement, { backgroundColor: '#FFFFFF' });
            var saveJson    = JSON.parse(mask.imageJson)
            let maskColor   = mask.color ? mask.color : '#FFFFFF';
            saveCanvas.loadFromJSON(saveJson, function() {  
                saveCanvas.backgroundColor = '#FFFFFF';  
                saveCanvas.backgroundImage = null;
                saveCanvas.forEachObject(function(object) {
                    object.set({color: maskColor});
                });
                saveCanvas.renderAll();
                 }, function(o, object) {
                object.set({color: maskColor});
               
                saveCanvas.renderAll();
            });
            saveCanvas.renderAll();

            let canvasURL = saveCanvas.toDataURL({ format: 'png',quality: 1});
            //window.open(canvasURL);

            setTimeout(() => {
                let ctx = canvasElement.getContext('2d');
                let pixelData = ctx.getImageData(0, 0, mask.width, mask.height).data;
                let colors = new Set();
                for (let i = 0; i < pixelData.length; i += 4) {
                    let color = pixelData.slice(i, i + 3).join(',');
                    colors.add(color);
                }
                canvasElement.remove();
                
            }, 500);
            

            resolve(mask);
        });
    },*/

    uploadMaskS3: async function(mask, bucket, fileKey, color = false, dataset = false, reSize = false) {
        const helper = require("./helper");
        var canvasElement = document.createElement('canvas');

        if(reSize){
            mask.width  = reSize.width
            mask.height = reSize.height
        }

        canvasElement.setAttribute('id', 'canvas');
        canvasElement.setAttribute('width', mask.width);
        canvasElement.setAttribute('height', mask.height);
        let saveCanvas  = new fabric.Canvas(canvasElement, { });
        var saveJson = false//JSON.parse(mask.imageJson)
        try { saveJson = JSON.parse(mask.imageJson)
        } catch (error) {  //Compressed with lz-string
            saveJson = JSON.parse(lzstring.decompressFromUint8Array(mask.imageJson.toUint8Array())) ;
        }

        if(dataset && dataset.type=='MULTILABEL'){
            saveCanvas.loadFromJSON(saveJson, 
                function() {  
                    //set canvas size
                    saveCanvas.setWidth(mask.width);
                    saveCanvas.setHeight(mask.height);
                    saveCanvas.backgroundImage = null;
                    saveCanvas.backgroundColor = '#FFFFFF';  
                    saveCanvas.forEachObject(function(object) {
                        if (object.type === 'image' && object.backgroundImage)object.backgroundImage = null;
                        object.set({shadow: null});
                    });
                    saveCanvas.renderAll(); },
                function(o, object) {
                    object.shadow = null
                    saveCanvas.backgroundColor = '#FFFFFF';
                    saveCanvas.renderAll();
            });

            setTimeout(() => {
                let colorValues = Object.values(color);
                let tagRGBs     = colorValues.map(_color => helper.getRgbValues(helper.hexToRgb(_color)));
                let ctx = canvasElement.getContext('2d');
                let pixelData = ctx.getImageData(0, 0, mask.width, mask.height).data;
                for (let i = 0; i < pixelData.length; i += 4) {
                    let currentColor = pixelData.slice(i, i + 3).join(',');
                    if (!tagRGBs.includes(currentColor)) {
                    pixelData[i] = 255;
                    pixelData[i + 1] = 255;
                    pixelData[i + 2] = 255;
                    pixelData[i + 3] = 255;
                    }
                }
                ctx.putImageData(new ImageData(pixelData, mask.width, mask.height), 0, 0);
                let canvasURL = canvasElement.toDataURL({ format: 'png', quality: 1 });
                canvasElement.remove();
                let bucketParams = {
                    Bucket: bucket,
                    Key: fileKey,
                    Body: this.dataURLtoBlob(canvasURL)
                };
                console.log('put mask!',bucketParams)
                this.s3.putObject(bucketParams,function(err){ if (err)console.log('save mask error', err)  })
              }, 500);

        }else{
            saveCanvas.loadFromJSON(saveJson, 
                function() {  
                    let maskColor = color ? color : mask.color
                    saveCanvas.setWidth(mask.width);
                    saveCanvas.setHeight(mask.height);
                    saveCanvas.backgroundImage = null;
                    saveCanvas.backgroundColor = '#FFFFFF';  
                    saveCanvas.forEachObject(function(object) {
                        if (object.type === 'image' && object.backgroundImage)object.backgroundImage = null;
                        object.set({stroke: maskColor, color: maskColor, shadow: null});
                    });
                    saveCanvas.renderAll(); },
                function(o, object) {
                    let maskColor = color ? color : mask.color
                    object.shadow = null
                    object.set({stroke: maskColor, color: maskColor , shadow: null});
                    saveCanvas.backgroundColor = '#FFFFFF';
                    saveCanvas.renderAll();
            });

            setTimeout(() => {
                let tagRGB    = helper.getRgbValues(helper.hexToRgb(color));
                let ctx       = canvasElement.getContext('2d');
                let pixelData = ctx.getImageData(0, 0, mask.width, mask.height).data;
                for (let i = 0; i < pixelData.length; i += 4) {
                    let color = pixelData.slice(i, i + 3).join(',');
                    if(color != "255,255,255" && color != tagRGB){
                      pixelData[i]   = 255;
                      pixelData[i+1] = 255;
                      pixelData[i+2] = 255;
                      pixelData[i+3] = 255;
                    }
                }
                ctx.putImageData(new ImageData(pixelData, mask.width, mask.height), 0, 0);
                let canvasURL = canvasElement.toDataURL({ format: 'png',quality: 1});
                canvasElement.remove();
                let bucketParams = {
                    Bucket: bucket,
                    Key:    fileKey,
                    Body:   this.dataURLtoBlob(canvasURL)
                };
                console.log('put mask!',bucketParams)
                this.s3.putObject(bucketParams,function(err){ if (err)console.log('save mask error', err)  })
              }, 500);
        }

        
    },

    uploadS3: async function(datasetID, tagMap = false, projectName = false, unifySize = false, uploadImageType = "image/png") {
        const dataset = require("./dataset");
        const event   = require("./event");
        const helper  = require("./helper");
        let resp      = { status: "error", error: false, bucket: this.config.awsConfig.bucket }
        resp.dataset  = datasetID  
        let images    = await dataset.getImages({ datasetID: resp.dataset }) 
        if(tagMap)resp.tagMap = tagMap
        if(images.media && images.media.length){

            console.log('-----------------> Init Upload to S3, dataset images for check:', images.media.length)

            var nowdate          = new Date()
            let configS3         = this.getConfig()
            let _dataset         = await dataset.get(datasetID)
            let datasetTags      = await dataset.getTags(datasetID)
            resp.projectName     = configS3.projectId 
            resp.datasetPathName = projectName ? projectName : _dataset.name.toString() + "_" + nowdate.getTime()
            resp.path            = "projects/" + resp.projectName  + '/datasets/' + resp.datasetPathName
            resp.uploadedFiles   = { count: 0, normal: 0, anomaly: 0, files: [] };
            resp.manifest        = { test: "", train: ""}

            for (var i = 0; i < images.media.length; i++) { 
                let uploadFile  = true
                let tagId       = images.media[i].tag ? images.media[i].tag.toString().split('/').pop() : false
                let excludeUnclasified = false
                if(!datasetTags[tagId].unclassified)excludeUnclasified = true
                if(_dataset.type=='MULTILABEL')excludeUnclasified = true
                if(tagId && datasetTags[tagId] && excludeUnclasified){ 
                    if(resp.tagMap && images.media[i].tag){
                        var imagePath   = resp.path
                        var tagName     = datasetTags[tagId].name.toString().toUpperCase()
                        let notIncluded = true  
                        if (resp.tagMap.normal.includes(tagName)){ tagName = "normal"; resp.uploadedFiles.normal++; notIncluded = false }
                        if (resp.tagMap.anomaly.includes(tagName)){ tagName = "anomaly"; resp.uploadedFiles.anomaly++; notIncluded = false }
                        imagePath +=  '/' + tagName
                        if(notIncluded){ uploadFile = false }
                    }else{ uploadFile = false }
                }else{ uploadFile = false }
               
                if(uploadFile){
                    try {
                        let imgRef     = await $_firebase.storage().refFromURL(images.media[i].uri).getDownloadURL(); 
                        let fileKey    = imagePath + "/" + images.media[i].uri.split("/").pop()
                        let fileKeyExt = fileKey.split('.').pop();
                        if (!fileKeyExt.match(/(jpg|jpeg|png|bmp|gif)$/i))fileKey += '.png';

                        if(!unifySize){
                            const firstImageSize = await this.loadCompressImage(imgRef);
                            unifySize = { width: firstImageSize.width, height: firstImageSize.height }
                        }
                        resp.uploadedFiles.files.push(fileKey)

                        //const compressedImage = await this.compressImage(imgRef, 0.8, 'image/png', unifySize);
                        const compressedImage = await this.compressImage(imgRef, 0.8, uploadImageType, unifySize);
                        let bucketParams = {
                            Bucket: resp.bucket,
                            Key:    fileKey,
                            Body:   compressedImage,
                        };
                        var requestPut = this.s3.putObject(bucketParams);
                        var resultPut  = await requestPut.promise();
                        console.log('result PUT', resultPut);

                        resp.uploadedFiles.count++;
                        let imgSet = "test"
                        if(images.media[i].set=="TRAIN")imgSet = "train"
                        if(!images.media[i].set || images.media[i].set.toString().toLowerCase()=="predetermined")imgSet = "train"
                        let mJSON     = '"source-ref" : "s3://' + this.config.awsConfig.bucket + "/" + fileKey + '",'
                        mJSON        += '"anomaly-label": ' + (tagName == "anomaly" ? '1' : '0') + ',' 
                        mJSON        += '"anomaly-label-metadata": {"job-name": "labeling-job/classification-job", "class-name": "' + tagName +'", "human-annotated": "yes", "creation-date": "' + nowdate.toISOString() + '", "type": "groundtruth/image-classification" }' //"job-name": "anomaly-label"
                        
                        if(tagName == "anomaly" && images.media[i] && images.media[i].mask && images.media[i].mask.imageJson && tagMap.useMask){
                            console.log('--------------------> Save S3 Mask '+ images.media[i].id) 

                            var defectName  = tagId.toString()
                            var defectColor = images.media[i].mask.color 

                            if(tagMap.anomalyLabel){
                                if(Object.keys(tagMap.anomalyLabel).length){
                                    for(var key in tagMap.anomalyLabel){
                                        if(tagMap.anomalyLabel[key]){
                                            if(tagMap.anomalyLabel[key].includes(tagId.toString().toUpperCase())) {
                                                defectName  = key.toString()
                                                defectColor = helper.StringtoHex(key)
                                            }
                                        }
                                    }
                                }
                            }

                            let maskPathKey = resp.path + "/mask/" + images.media[i].uri.split("/").pop().replace('.jpg', '.png')
                            maskPathKey = maskPathKey.replace('.bmp', '.png')
                            let maskPathKeyExt = maskPathKey.split('.').pop();
                            if (!maskPathKeyExt.match(/(jpg|jpeg|png|bmp|gif)$/i))maskPathKey += '.png';

                            let multiLabelTagList = false
                            if(_dataset && _dataset.type=='MULTILABEL'){
                                var saveMaskJson = false
                                try { saveMaskJson = JSON.parse(images.media[i].mask.imageJson)
                                } catch (error) {  //Compressed with lz-string
                                    saveMaskJson = JSON.parse(lzstring.decompressFromUint8Array(images.media[i].mask.imageJson.toUint8Array())) ;
                                }
                                multiLabelTagList = saveMaskJson.objects.reduce((obj, item) => { obj[item.name] = item.stroke; return obj; }, {});
                                defectColor       = multiLabelTagList
                                console.log('multilabel taglist', multiLabelTagList)
                            }

                            await this.uploadMaskS3(images.media[i].mask, resp.bucket, maskPathKey, defectColor, _dataset, unifySize)

                            console.log('UPLOAD MASK S3',images.media[i].mask, resp.bucket, maskPathKey, defectColor, _dataset, unifySize)
                            
                            mJSON     += ','
                            mJSON     += '"anomaly-mask-ref" : "s3://' + this.config.awsConfig.bucket + "/" + maskPathKey + '",'
                            let internalColorMap  = '{ '
                            internalColorMap     += '"0": {"class-name": "BACKGROUND", "hex-color": "#ffffff", "confidence": 0.0 }'
                            
                            if(multiLabelTagList && Object.keys(multiLabelTagList).length){
                                var _i = 1
                                for(var key in multiLabelTagList){
                                    if(multiLabelTagList[key]){
                                        internalColorMap  += ',"'+_i+'": {"class-name": "'+key.toString().toUpperCase()+'", "hex-color": "'+ multiLabelTagList[key] +'", "confidence": 0.0 }' 
                                        _i++
                                    }
                                }
                            }else
                                internalColorMap += ',"1": {"class-name": "'+defectName+'", "hex-color": "'+ defectColor +'", "confidence": 0.0 }' 

                            internalColorMap     += ' }' 
                            mJSON     += '"anomaly-mask-ref-metadata": { "job-name": "labeling-job/segmentation-job", "internal-color-map": '+ internalColorMap +', "human-annotated": "yes", "creation-date": "' + nowdate.toISOString() + '", "type": "groundtruth/semantic-segmentation" }'
                            console.log('{ ' + mJSON + ' }\n')  
                        }

                        resp.manifest[imgSet] += '{ ' + mJSON + ' }\n'
                        /*let JsonImageManigfest = '{ '
                                                    + '"source-ref" : "s3://' + this.config.awsConfig.bucket + "/" + fileKey + '",'
                                                    + '"anomaly-label": ' + (tagName == "anomaly" ? '1' : '0') + ','  
                                                    + '"anomaly-label-metadata": {"job-name": "anomaly-label", "class-name": "' + tagName +'", "human-annotated": "yes", "creation-date": "' + nowdate.toISOString() + '", "type": "groundtruth/image-classification" }'
                                                    + ' }\n'*/
                        console.log('-----------------------> Put Image: ' + fileKey + ' to set ' + imgSet + '(' +resp.uploadedFiles.count+')')     
                    } catch (error) { console.log('-----------------------> Error Put Image: ' + error) }  
                }
            }
            resp.status   = "success";
            resp.manifest = await this.createManifest(resp)
            return resp
        }else{ resp.error = "The dataset " + resp.dataset + " does not have images"; return resp }
    },

    compressImage: async function(imageUrl, quality = 0.8, mimeType = 'image/jpeg', reSize = false) {
        const img = await this.loadCompressImage(imageUrl);
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        if(reSize){
            console.log('resize compressImage from ', img.width, img.height, ' to ', reSize.width, reSize.height)
            canvas.width  = reSize.width
            canvas.height = reSize.height
        }
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);

        const originalSizeMB = (await (await fetch(imageUrl)).blob()).size / (1024 * 1024);
        console.log(`PUT Object S3 Original image size: ${originalSizeMB.toFixed(2)} MB`);

        return new Promise((resolve, reject) => {
            canvas.toBlob(
            (blob) => {
                if (!blob) {
                reject(new Error('Image compression failed.'));
                }
                const compressedSizeMB = blob.size / (1024 * 1024);
                console.log(`PUT Object S3 Compressed image size: ${compressedSizeMB.toFixed(2)} MB`);
                resolve(blob);
            },
            mimeType,
            quality
            );
        });
    }, 
    
    loadCompressImage: async function(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error('Failed to load image.'));
            img.src = url;
          });
    },  

    createManifest: async function(opt) {
        return new Promise((resolve, reject) => {
            let resp          = { status: "success", error: false, manifest: { test: false, train: false } }
            if(opt.manifest){
                let datasetTypes  = ["test", "train"]
                for (var t = 0; t < datasetTypes.length; t++) {
                    if(opt.manifest[datasetTypes[t]]){
                        resp.manifest[datasetTypes[t]] = opt.path + '/manifests/' + opt.datasetPathName + "-" + datasetTypes[t] + ".manifest"
                        this.s3.putObject({
                                        Bucket: this.config.awsConfig.bucket,
                                        Key:    resp.manifest[datasetTypes[t]],
                                        Body:   new Blob([opt.manifest[datasetTypes[t]]], {type: "application/json"})
                                    }
                                    ,function(err){ if (err)resp.error = err  })
                    } 
                }
                resolve(resp.manifest) 
            }else{ resp.error = "error"; resp.error = "manifest content is required"; reject; resolve(resp) }
        }) 
    },

    listProjects: async function() {
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false }
            resp.region = this.lookoutConfig.region
            this.lookoutvision.listProjects({},function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    createProject: async function(projectName) {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false }
            this.lookoutvision.createProject( { ProjectName: projectName, },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
       
    },

    createDataset: async function(datasetType, projectName, manifest = false) {
        return new Promise((resolve, reject) => {
            let resp            = { status: "error", error: false, bucket: this.config.awsConfig.bucket }
            let datasetParams   = { DatasetType: datasetType, ProjectName: projectName };
            if(manifest){
                datasetParams.DatasetSource = {
                    GroundTruthManifest:  {
                        S3Object: {
                            Bucket: resp.bucket,
                            Key:    manifest,
                        }
                    }
                }
            }
            this.lookoutvision.createDataset( datasetParams,function(err, data) {
                if (!err && err === null){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    getDataset: async function(projectName) {
        let resp          = { status: "error", error: false, projectName: projectName, response: { dataset: {} } }
        let datasetTypes  = ["test", "train"]
        for (var t = 0; t < datasetTypes.length; t++) {
            if(datasetTypes[t]){
                let describe = await this.describeDataset({ DatasetType: datasetTypes[t], ProjectName: projectName })
                if(!describe.error)resp.response.dataset[datasetTypes[t]] = describe.response
                else resp.error = describe.error;
            } 
        }
        if(!resp.error)resp.status = "success"
        return resp
    },

    getDatasetEntries: async function(projectName) {
        let resp          = { status: "error", error: false, projectName: projectName, response: { counter: { test: { count: 0, normal: 0, anomaly: 0, nolabeled: 0 }, train: { count: 0, normal: 0, anomaly: 0, nolabeled: 0 } } } }
        let datasetTypes  = ["test", "train"]
        for (var t = 0; t < datasetTypes.length; t++) {
            if(datasetTypes[t]){
                let endList     = false
                let entries     = { normal: [], anomaly: [], nolabeled: []}
                var lastToken   = false
                while(!endList){
                    let qry  = { DatasetType: datasetTypes[t], ProjectName: projectName }
                    if(lastToken)qry.NextToken = lastToken
                    let list = await this.listDatasetEntries(qry)
                    if(!list.error){
                        if(list.response.NextToken)lastToken = list.response.NextToken
                        else endList = true
                        for(let e in list.response.DatasetEntries){ 
                            let entry = JSON.parse(list.response.DatasetEntries[e])
                            if(!("anomaly-label" in entry)){ entries.nolabeled.push(entry); resp.response.counter[datasetTypes[t]].nolabeled++  
                            }else{
                                if(entry["anomaly-label"]){ entries.anomaly.push(entry); resp.response.counter[datasetTypes[t]].anomaly++ 
                                }else{ entries.normal.push(entry); resp.response.counter[datasetTypes[t]].normal++ }
                            }
                            resp.response.counter[datasetTypes[t]].count++
                        }  
                        resp.response[datasetTypes[t]]       = entries
                    }else{ resp.error = list.error; endList = true }
                }
            } 
        }
        if(!resp.error)resp.status = "success"
        return resp
    },

    listDatasetEntries: async function(datasetParams) {
        return new Promise((resolve, reject) => {
            let resp       = { status: "error", error: false }
            this.lookoutvision.listDatasetEntries(datasetParams,function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    describeDataset: async function(datasetParams) {
        return new Promise((resolve, reject) => {
            let resp       = { status: "error", error: false }
            this.lookoutvision.describeDataset(datasetParams,function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    getProject: async function(projectName) {
        return new Promise((resolve, reject) => {
            let resp       = { status: "error", error: false, projectName: projectName}
            this.lookoutvision.describeProject({ ProjectName: projectName },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    listModels: async function(projectName) {
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName }
            resp.region = this.lookoutConfig.region
            this.lookoutvision.listModels({ ProjectName: projectName },function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resp.response.count = data.Models ? data.Models.length : 0
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },

    getModel: async function(projectName, modelVersion = false) {
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName, modelVersion: "latest" }
            if(modelVersion)resp.modelVersion = modelVersion
            resp.region = this.lookoutConfig.region
            this.lookoutvision.describeModel({ ProjectName: projectName, ModelVersion: resp.modelVersion },function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    if(resp.response.ModelDescription){
                        resp.response.evaluation = {
                            resultKey:   resp.response.ModelDescription.EvaluationResult ? resp.response.ModelDescription.EvaluationResult.Key : false,
                            manifestKey: resp.response.ModelDescription.EvaluationManifest ? resp.response.ModelDescription.EvaluationManifest.Key : false,
                        }
                    }   
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    
    getEvaluation: async function(projectName, modelVersion = false) {
        let resp   = { status: "error", error: false, projectName: projectName, modelVersion: modelVersion ? modelVersion : "latest" }
        var model  = await this.getModel(resp.projectName, resp.modelVersion)
        if(model.response && model.response.evaluation){
            console.log('evaluation respnse',model.response)
            let resultEvaluation = await this.getObject(model.response.evaluation.resultKey)
            if(resultEvaluation.response && resultEvaluation.response.Body) {

                //const res       = new Response(resultEvaluation.response.Body) 
                //resp.results    = await res.json()
            
            }else{ resp.error   = "model evaluation results not found" } 
            var manifestEvaluation = await this.getObject(model.response.evaluation.manifestKey)
            if(manifestEvaluation.response && manifestEvaluation.response.Body) {
                const res       = new Response(manifestEvaluation.response.Body) 
                let manifest    = await res.text()
                manifest        = JSON.parse("[" + manifest.replace(/\n/g, ",").slice(0, -1) + "]")
                console.log("manifest",manifest)
                if(Object.keys(manifest).length){
                    resp.manifestResume   = { 
                                              totalTestImages:   Object.keys(manifest).length,
                                              normal:           { total: 0, trueNegative:  0, falseNegative: 0, predictions: 0 },
                                              anomaly:          { total: 0, truePositive:  0, falsePositive: 0, predictions: 0},
                                            }
                    resp.manifest         = manifest   
                    for (var i = 0; i < Object.keys(resp.manifest).length; i++) {
    
                        let objKey              = resp.manifest[i]["source-ref"].replace(/s3:/g, "").replace(/\/\//g, "").replace(new RegExp(this.config.awsConfig.bucket+"/", "g"), "");
                        resp.manifest[i]["key"] = objKey 
                        
                        if(resp.manifest[i]["anomaly-label"]){
                            if(resp.manifest[i]["anomaly-label-detected"]){
                                resp.manifestResume.anomaly.truePositive++
                                resp.manifestResume.anomaly.total++
                            }else{
                                resp.manifestResume.normal.falseNegative++
                                resp.manifestResume.normal.total++
                            } 
                            resp.manifestResume.anomaly.predictions++
                        }else{
                            if(!resp.manifest[i]["anomaly-label-detected"]){
                                resp.manifestResume.normal.trueNegative++
                                resp.manifestResume.normal.total++
                            }else{
                                resp.manifestResume.anomaly.falsePositive++
                                resp.manifestResume.anomaly.total++
                            }
                            resp.manifestResume.normal.predictions++
                        }
                        

                        /*
                        if(resp.manifest[i]["anomaly-label"]){
                            if(resp.manifest[i]["anomaly-label"])resp.manifestResume.anomaly.truePositive++
                            else resp.manifestResume.anomaly.falsePositive++
                            resp.manifestResume.anomaly.total++
                        }else{
                            if(!resp.manifest[i]["anomaly-label"])resp.manifestResume.normal.trueNegative++
                            else resp.manifestResume.normal.falsePositive++
                            
                        }
                        */
                    }
                }
            }
        }else{ resp.error = "model evaluation results not found" }
        return new Promise((resolve, reject) => {
            if(resp.error)reject 
            else resp.status = "success"
            resolve(resp)
        }) 
    },  
    
    getModelPackagingJobs: async function(projectName, modelVersion = false) {
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName }
            resp.region = this.lookoutConfig.region
            let opt     = { ProjectName: projectName }
            this.lookoutvision.listModelPackagingJobs(opt, function(err, data) {
                if(modelVersion){
                    console.log('search version', modelVersion)
                    let packages = []
                    for(var i = 0; i < data.ModelPackagingJobs.length; i++){
                        if(data.ModelPackagingJobs[i].ModelVersion == modelVersion){
                            console.log('model version', data.ModelPackagingJobs[i].ModelVersion)
                            packages.push(data.ModelPackagingJobs[i])
                        }
                    }
                    data.ModelPackagingJobs = packages
                }
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    }, 
    
    getModelPackagingJob: async function(projectName, jobName){
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName, jobName: jobName  }
            resp.region = this.lookoutConfig.region
            this.lookoutvision.describeModelPackagingJob({ ProjectName: projectName, JobName: jobName }, function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
                this.greengrass.listDeployments
            })
        }) 
    }, 

    listDeployments: async function(projectName){
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName }
            resp.region = this.lookoutConfig.region
            this.greengrass.listDeployments({ GroupId: projectName }, function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        })
    },

    /*
    createPackingJob: async function(projectName, jobName, modelVersion = false) {
        this.lookoutvision.startModelPackagingJob( { 
                                                ProjectName: projectName,
                                                Configuration: {
                                                                "Greengrass": {
                                                                    "CompilerOptions": "string",
                                                                    "TargetDevice": "jetson_xavier",
                                                                    "TargetPlatform": {
                                                                        "Os": "LINUX",
                                                                        "Arch": "ARM64"|"X86_64",
                                                                        "Accelerator": "NVIDIA"
                                                                    },
                                                                    "S3OutputLocation": {
                                                                        "Bucket": "string",
                                                                        "Prefix": "string"
                                                                    },
                                                                    "ComponentName": "string",
                                                                    "ComponentVersion": "string",
                                                                    "ComponentDescription": "string",
                                                                    "Tags": [
                                                                        {
                                                                        "Key": "string",
                                                                        "Value": "string"
                                                                        }
                                                                    ]
                                                                    }
                                                                },
                                                ModelVersion: "1",
                                                JobName: "TrainingJobName"                

                                              }, function(err, data) {
            if (!err){
                resp.status         = "success"
                resp.response       = data
                resolve(resp)
            }
            resp.error = err; reject
            resolve(resp)
        })
    },

    createDeployment: async function(projectName) {
        return new Promise((resolve, reject) => {
            let resp     = { status: "error", error: false, projectName: projectName }
            resp.region  = lookoutConfig.region
            greengrass.createDeployment(
                                        {
                                            "clientToken": "string",
                                            "components": { 
                                            "string" : { 
                                                "componentVersion": "string",
                                                "configurationUpdate": { 
                                                    "merge": "string",
                                                    "reset": [ "string" ]
                                                },
                                                "runWith": { 
                                                    "posixUser": "string",
                                                    "systemResourceLimits": { 
                                                        "cpus": number,
                                                        "memory": number
                                                    },
                                                    "windowsUser": "string"
                                                }
                                            }
                                            },
                                            "deploymentName": "string",
                                            "deploymentPolicies": { 
                                            "componentUpdatePolicy": { 
                                                "action": "string",
                                                "timeoutInSeconds": number
                                            },
                                            "configurationValidationPolicy": { 
                                                "timeoutInSeconds": number
                                            },
                                            "failureHandlingPolicy": "string"
                                            },
                                            "iotJobConfiguration": { 
                                            "abortConfig": { 
                                                "criteriaList": [ 
                                                    { 
                                                        "action": "string",
                                                        "failureType": "string",
                                                        "minNumberOfExecutedThings": number,
                                                        "thresholdPercentage": number
                                                    }
                                                ]
                                            },
                                            "jobExecutionsRolloutConfig": { 
                                                "exponentialRate": { 
                                                    "baseRatePerMinute": number,
                                                    "incrementFactor": number,
                                                    "rateIncreaseCriteria": { 
                                                        "numberOfNotifiedThings": number,
                                                        "numberOfSucceededThings": number
                                                    }
                                                },
                                                "maximumPerMinute": number
                                            },
                                            "timeoutConfig": { 
                                                "inProgressTimeoutInMinutes": number
                                            }
                                            },
                                            "parentTargetArn": "string",
                                            "tags": { 
                                            "string" : "string" 
                                            },
                                            "targetArn": "string"
                                        }
                , function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    */

    createModel: async function(projectName) {
        return new Promise((resolve, reject) => {
            let resp     = { status: "error", error: false, projectName: projectName }
            resp.region  = this.lookoutConfig.region
            let model    = {
                            ProjectName: projectName,
                            OutputConfig: {
                                S3Location: {  
                                    Bucket: this.config.awsConfig.bucket,
                                    Prefix: "components/" + projectName.toString().replace(/\s+/g, '_') 
                                }
                            }
                          }
                          this.lookoutvision.createModel(model, function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    }, 

    UpdateDatasetEntries: async function(datasetID) {
        let resp     = { status: "error", error: false, bucket: config.awsConfig.bucket }
        resp.dataset = datasetID  
        return resp
    }, 
}

module.exports = aws